import type { ReactElement } from 'react';
import React from 'react';

type Props = { className?: string };

// TODO: Import this icon from HDS when it's added there
const IconMovies = ({ className = '' }: Props): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    className={className}
  >
    <g strokeWidth="1" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path d="M21.872811,2.03509277 L22.206341,4.14128525 L4.01198033,7.93754754 L22.81,7.93754754 L22.8111607,9.23987541 L22.8111607,22.7485967 L1.00014426,22.7485967 L1.00014426,7.88921967 L1.81486557,6.18980984 L21.872811,2.03509277 Z M21.2224721,12.6740393 L2.61335738,12.6938754 L2.61335738,21.1891213 L21.2224721,21.1891213 L21.2224721,12.6740393 Z M10.4746787,13.8607049 L15.0746787,16.8451428 L10.4746787,19.8607049 L10.4746787,13.8607049 Z M21.2224721,9.90276066 L18.4486689,9.90276066 L19.3888984,11.1809246 L21.2224721,11.1809246 L21.2224721,9.90276066 Z M16.0477836,9.90276066 L12.9021443,9.90276066 L13.8344393,11.1809246 L16.9880131,11.1809246 L16.0477836,9.90276066 Z M10.5081115,9.90276066 L7.19332459,9.90276066 L8.11948852,11.1809246 L11.4411279,11.1809246 L10.5081115,9.90276066 Z M4.80578361,9.90276066 L2.61335738,9.90276066 L2.61335738,11.1809246 L5.73266885,11.1809246 L4.80578361,9.90276066 Z" />
    </g>
  </svg>
);

export default IconMovies;
